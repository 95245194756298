// extracted by mini-css-extract-plugin
export var container = "member-single-module--container--4WM58";
export var student = "member-single-module--student--zGvHy";
export var studentImg = "member-single-module--studentImg--3rW5o";
export var studentName = "member-single-module--studentName--3oEGO";
export var studentInformations = "member-single-module--studentInformations--1oXYp";
export var studentInformation = "member-single-module--studentInformation--m6KN5";
export var studentInformationDepartment = "member-single-module--studentInformationDepartment--3Rtg0";
export var studentInformationGraduate = "member-single-module--studentInformationGraduate--3bz0E";
export var studentInformationResearch = "member-single-module--studentInformationResearch--dDnH0";
export var studentInformationResearchLink = "member-single-module--studentInformationResearchLink--3T1f9";
export var studentInformationPortfolio = "member-single-module--studentInformationPortfolio--11IK4";
export var studentDescription = "member-single-module--studentDescription--Byacj";
export var classmate = "member-single-module--classmate--2K5gw";
export var classmateTitle = "member-single-module--classmateTitle--ZtJKw";
export var classmateItems = "member-single-module--classmateItems--2hxmN";
export var classmateItem = "member-single-module--classmateItem--BNQTN";
export var classmateItemImg = "member-single-module--classmateItemImg--umTod";
export var classmateItemName = "member-single-module--classmateItemName---kdGG";
export var classmateItemGraduate = "member-single-module--classmateItemGraduate--2zcaZ";
export var classmateItemNotice = "member-single-module--classmateItemNotice--2RKsw";