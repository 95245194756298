import * as React from 'react'
import * as styles from '../components/member-single.module.scss'
import GlobalWrapper from '../components/GlobalWrapper'
import openInNew from '../images/open_in_new.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => {
  const singleData = data.single.frontmatter
  const listData = data.list

  return (
    <>
      <SEO
        description={singleData.description}
        image={singleData.image.childImageSharp.gatsbyImageData}
        title={singleData.name}
      />
      <main className={styles.container}>
        <GlobalWrapper>
          <div className={styles.student}>
            <h1 className={styles.studentName}>{singleData.name}</h1>
            <GatsbyImage
              className={styles.studentImg}
              image={getImage(singleData.image.childImageSharp.gatsbyImageData)}
              alt=""
            />
            <div className={styles.studentInformations}>
              <div className={styles.studentInformation}>
                <p className={styles.studentInformationDepartment}>
                  卒業課程：{singleData.graduateCurriculum}
                </p>
                <p className={styles.studentInformationDepartment}>
                  コース/領域：{singleData.graduateCourse}
                  {singleData.graduateField && `/${singleData.graduateField}`}
                </p>
                <p className={styles.studentInformationGraduate}>
                  卒業年度：{singleData.graduateYear}年
                </p>
                <p className={styles.studentInformationResearch}>
                  研究：
                  <Link
                    to={'/researchs/' + singleData.key}
                    className={styles.studentInformationResearchLink}
                  >
                    {singleData.researchTitle}
                  </Link>
                </p>
                {singleData.portfolio && (
                  <a
                    className={styles.studentInformationPortfolio}
                    href={singleData.portfolio}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    ポートフォリオサイト
                  </a>
                )}
              </div>
              <p className={styles.studentDescription}>
                {singleData.description}
              </p>
            </div>
          </div>
          <div className={styles.classmate}>
            <h2 className={styles.classmateTitle}>同期生</h2>
            {listData.edges.length !== 0 ? (
              <div className={styles.classmateItems}>
                {listData.edges.map(item => (
                  <Link
                    to={'/members/' + item.node.frontmatter.key}
                    className={styles.classmateItem}
                  >
                    <h3 className={styles.classmateItemName}>
                      {item.node.frontmatter.name}
                    </h3>
                    <GatsbyImage
                      className={styles.classmateItemImg}
                      image={getImage(
                        item.node.frontmatter.image.childImageSharp
                          .gatsbyImageData
                      )}
                      alt=""
                    />
                    <p className={styles.classmateItemGraduate}>
                      {item.node.frontmatter.graduateYear}年度卒
                    </p>
                  </Link>
                ))}
              </div>
            ) : (
              <p className={styles.classmateItemNotice}>
                同期の研究生のデータはありません
              </p>
            )}
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}
export const query = graphql`
  query ($key: String, $admissionYear: Date) {
    single: markdownRemark(
      fileAbsolutePath: { regex: "/members/" }
      frontmatter: { key: { eq: $key } }
    ) {
      frontmatter {
        key
        name
        graduateCourse
        graduateField
        graduateCurriculum
        graduateYear
        description
        portfolio
        researchTitle
        image {
          childImageSharp {
            gatsbyImageData(width: 700)
          }
        }
      }
    }
    list: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/members/" }
        frontmatter: {
          admissionYear: { eq: $admissionYear }
          key: { ne: $key }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            key
            name
            admissionYear
            graduateYear
            image {
              childImageSharp {
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
